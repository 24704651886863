/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.4.1 ./public/models/loading-ring/loading-ring.glb 
*/

import React, { useEffect, useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";

export default function LoadingRing(props) {
  const { nodes, materials } = useGLTF("/models/loading-ring/loading-ring.glb");

  const ring = useRef();

  useEffect(() => {
    if (ring){
      ring.current.material.transparent = true;
      ring.current.material.depthTest = true;
    }
  }, [])

  useFrame((state, delta) => {
    ring.current.rotation.y += delta * 1.25;
  })

  return (
    <group {...props} dispose={null}>
      <mesh
        ref={ring}
        geometry={nodes["loading-ring"].geometry}
        material={materials["Material.002"]}
      />
    </group>
  );
}

useGLTF.preload("/models/loading-ring/loading-ring.glb");
